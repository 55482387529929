.calendar {
    display: block;
    max-width: 100%;
    height: auto;
    overflow: visible;
  }
  
  .calendar text {
    fill: currentColor;
  }
  
  .block {
    stroke: rgba(0, 0, 0, 0.1);
    stroke-width: 1px;
    shape-rendering: geometricPrecision;
  }
  
  .footer {
    display: flex;
  }
  
  .legendColors {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 0.2em;
  }
  
  /*noinspection CssUnresolvedCustomProperty*/
  @keyframes loadingAnimation {
    0% {
      fill: var(--react-activity-calendar-loading);
    }
    50% {
      fill: var(--react-activity-calendar-loading-active);
    }
    100% {
      fill: var(--react-activity-calendar-loading);
    }
  }